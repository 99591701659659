import Popover from 'bootstrap/js/dist/popover';

const cantons = document.querySelectorAll(".swiss-map-wrapper svg path");
let cantonLabels = document.querySelectorAll("#canton-labels span");
let labels = [];
cantonLabels.forEach((cantonLabel) => {
    labels.push({label: cantonLabel.classList, value: cantonLabel.dataset.cantonName})
})
cantons.forEach((canton) => {
    const label = labels.find((label) => label.value === canton.id).label;
    const popover = new Popover(canton, {
        trigger: 'hover',
        content: `${label}`,
        placement: 'top',
    });
})
