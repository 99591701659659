const contactForm = document.querySelector('form#basic-contact-form');
const recaptchaV3PublicKey = window.sessionStorage.getItem('recaptchaV3PublicKey');

(() => {
    const recaptchaUrl = `https://www.google.com/recaptcha/api.js?render=${recaptchaV3PublicKey}`;
    const existingTag = document.head.querySelector(`script[src="${recaptchaUrl}"]`);

    if (!existingTag) {
        const scriptTag = document.createElement('SCRIPT');
        scriptTag.setAttribute('src', recaptchaUrl);
        document.head.appendChild(scriptTag);
    }
})();

(() => {
    const submitButton = document.querySelector('#contact-form-submit-button');
    const contactForm = document.querySelector('#basic-contact-form');

    if (!contactForm) {
        console.warn('contact form could not be found')
        return undefined;
    }
    if (!submitButton) {
        console.warn('contact form submit button could not be found')
        return undefined;
    }
    if (!recaptchaV3PublicKey) {
        console.warn('no recaptcha public key found')
        return undefined;
    }

    submitButton.addEventListener('click', (e) => {
        e.preventDefault();

        const isValid = contactForm.reportValidity();
        if (!isValid) {
            return;
        }

        grecaptcha.ready(function () {
            grecaptcha.execute(recaptchaV3PublicKey, {action: 'submit'}).then(function (token) {
                const recaptchaInput = contactForm.querySelector('input[name="gRecaptchaResponse"]');
                recaptchaInput.setAttribute('value', token);

                contactForm.submit();
            });
        });
    });
})();
