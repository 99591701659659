import Collapse from 'bootstrap/js/src/collapse';
const links = document.querySelectorAll('.navbar-nav .nav-link');
const buttons = document.querySelectorAll('.navbar-collapse .call-to-actions .btn');
const mobileNavbar = document.getElementById('navbarWSHSupport');
const bsCollapse = Collapse.getOrCreateInstance(mobileNavbar, {toggle: false});

const windowWidth = window.innerWidth;
if (windowWidth < 992) {
    links.forEach((link) => {
        link.addEventListener('click', () => {
            bsCollapse.hide()
        });
    });

    buttons.forEach((button) => {
        button.addEventListener('click', () => {
            bsCollapse.hide()
        });
    });
}
