import Modal from 'bootstrap/js/src/modal';

const imageGrid = document.querySelector(".image-grid");

if (imageGrid) {
    const images = imageGrid.querySelectorAll("img");
    const lightboxModal = document.getElementById("lightbox-modal");
    const bsModal = new Modal(lightboxModal);
    const carousel = document.getElementById("gallery-carousel-reliag");
    const carouselImages = document.querySelectorAll('.carousel-inner .carousel-item');

    images.forEach((image) => {
        image.addEventListener("click", (e) => {
            carouselImages.forEach((carouselImage) => {
                carouselImage.classList.remove("active");
            });

            const currentImage = carousel.querySelector(".carousel-inner img[src='" + image.dataset.fullSizeImage + "']");
            currentImage.parentElement.classList.add("active");
            bsModal.show();
        });
    })
}

