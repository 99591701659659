/**
 * @param {Element} elem
 * @returns {{top: number, left: number}}
 */
export const getElementCoords = (elem) => {
    let box = elem.getBoundingClientRect();

    return {
        top: box.top + pageYOffset,
        left: box.left + pageXOffset
    };
};
